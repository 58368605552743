import revive_payload_client_X4Q76U6Wtk from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@18.19.64_eslint@8.57.1_sass@1.81.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_IiT6Y7MJmk from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@18.19.64_eslint@8.57.1_sass@1.81.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_6hennZF3Hw from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@18.19.64_eslint@8.57.1_sass@1.81.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_PJ2Ms9PNq6 from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@18.19.64_eslint@8.57.1_sass@1.81.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_LwsIkvdcUO from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@18.19.64_eslint@8.57.1_sass@1.81.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LOdVVF8OT1 from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@18.19.64_eslint@8.57.1_sass@1.81.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_AzxH6uXRPF from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@18.19.64_eslint@8.57.1_sass@1.81.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_r3QkmFAo2k from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/@pinia+nuxt@0.5.5_typescript@5.6.3_vue@3.5.13/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/Partout/sendchallenges/src/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_LthdDiE4P3 from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@18.19.64_eslint@8.57.1_sass@1.81.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_MDo6A1A42i from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_pinia@2.2.6/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import apollo_1ILFkzpYSr from "/builds/Partout/sendchallenges/src/frontend/plugins/apollo.ts";
import i18n_VfGcjrvSkj from "/builds/Partout/sendchallenges/src/frontend/plugins/i18n.ts";
import sentry_3AyO8nEfhE from "/builds/Partout/sendchallenges/src/frontend/plugins/sentry.ts";
import toast_ysMjUcU7eJ from "/builds/Partout/sendchallenges/src/frontend/plugins/toast.ts";
export default [
  revive_payload_client_X4Q76U6Wtk,
  unhead_IiT6Y7MJmk,
  router_6hennZF3Hw,
  payload_client_PJ2Ms9PNq6,
  navigation_repaint_client_LwsIkvdcUO,
  check_outdated_build_client_LOdVVF8OT1,
  chunk_reload_client_AzxH6uXRPF,
  plugin_vue3_r3QkmFAo2k,
  components_plugin_KR1HBZs4kY,
  prefetch_client_LthdDiE4P3,
  plugin_MDo6A1A42i,
  apollo_1ILFkzpYSr,
  i18n_VfGcjrvSkj,
  sentry_3AyO8nEfhE,
  toast_ysMjUcU7eJ
]