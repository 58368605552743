<!-- components/SendInput.vue -->
<template>
  <div :class="wrapperClasses">
    <label v-if="label" v-once :class="labelClasses">
      {{ label }}
    </label>

    <div class="relative h-full">
      <component
        :is="type"
        v-model="model"
        :class="[baseInputClasses, dynamicStateClasses]"
        :disabled="disabled"
        :placeholder="placeholder"
        :rows="rows"
        :type="currentSubType"
        :value="model"
        v-bind="$attrs"
        @input="onInput"
      />

      <button
        v-if="subType === 'password'"
        class="absolute right-3 top-1/2 -translate-y-1/2 transform"
        type="button"
        @click="togglePasswordVisibility"
      >
        <i :class="iconClass" />
      </button>
    </div>

    <p
      v-if="validationStatus !== 'none' && validationMessage"
      :class="validationClasses"
    >
      {{ validationMessage }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { useVModel } from "@vueuse/core";
import { computed, ref } from "vue";
import type { Maybe } from "~/gql/graphql";
import { cn } from "~/utils/classNames";

export type Sizes = "lg" | "md" | "sm" | "xs";
export type InputTypes = "input" | "textarea";
export type SubTypes =
  | "text"
  | "password"
  | "email"
  | "number"
  | "tel"
  | "url"
  | "date"
  | "time"
  | "datetime-local"
  | "month"
  | "week";
export type ValidationStatus = "success" | "error" | "hint" | "none";

export interface Option {
  value: string | number;
  label: string;
}

const inputStyles = {
  base: "flex flex-col justify-center items-start gap-2 bg-white border border-solid border-send-almost-black rounded p-2 transition duration-200 w-full",

  sizes: {
    lg: "text-lg px-4 py-2.5 rounded-2xl",
    md: "text-base px-3 py-2 rounded-xl",
    sm: "text-sm px-2 py-1.5 rounded-lg",
    xs: "text-xs px-1.5 py-1 rounded-sm",
  },

  validation: {
    success: "border-send-success",
    error: "border-red-500",
    hint: "border-send-grey",
    none: "",
  },

  state: {
    disabled: "user-select-none opacity-50 cursor-not-allowed",
  },

  wrapper: {
    base: "relative flex flex-col align-middle justify-center w-full",
    spacing: {
      lg: "space-y-2",
      md: "space-y-2",
      sm: "space-y-1",
      xs: "space-y-1",
    },
  },

  label: {
    base: "text-sm font-medium text-send-almost-black",
    sizes: {
      lg: "text-md",
      md: "text-sm",
      sm: "text-xs",
      xs: "text-xs",
    },
  },

  validation_message: {
    base: "text-xs font-medium",
    status: {
      success: "text-send-success",
      error: "text-red-500",
      hint: "text-send-grey",
      none: "",
    },
  },
};

defineOptions({
  inheritAttrs: false,
});

interface Props {
  size?: Sizes;
  type?: InputTypes;
  subType?: SubTypes;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  modelValue?:
    | string
    | number
    | undefined
    | Ref<string | number | undefined>
    | Maybe<string>;
  validationStatus?: ValidationStatus;
  validationMessage?: string;
  options?: Option[];
  rows?: number;
}

const props = withDefaults(defineProps<Props>(), {
  size: "md",
  type: "input",
  subType: "text",
  disabled: false,
  label: undefined,
  placeholder: undefined,
  modelValue: undefined,
  validationStatus: "none",
  validationMessage: undefined,
  options: undefined,
  rows: 2,
});

const wrapperClasses = cn(
  inputStyles.wrapper.base,
  inputStyles.wrapper.spacing[props.size]
);

const labelClasses = cn(
  inputStyles.label.base,
  inputStyles.label.sizes[props.size]
);

const baseInputClasses = cn(inputStyles.base, inputStyles.sizes[props.size]);

const validationClasses = cn(
  inputStyles.validation_message.base,
  inputStyles.validation_message.status[props.validationStatus]
);

const dynamicStateClasses = computed(() => ({
  [inputStyles.validation[props.validationStatus]]:
    props.validationStatus !== "none",
  [inputStyles.state.disabled]: props.disabled,
}));

const model = useVModel(props, "modelValue");

const currentSubType = ref(props.subType);

const togglePasswordVisibility = () => {
  if (props.subType === "password") {
    currentSubType.value =
      currentSubType.value === "password" ? "text" : "password";
  }
};

const toggleVisibility = (visible: boolean) => {
  currentSubType.value = visible ? "text" : "password";
};

const iconClass = computed(() => {
  return currentSubType.value === "password"
    ? "icon icon-Eye"
    : "icon icon-EyeClosed";
});

const onInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  model.value = target.value;
};

defineExpose({
  toggleVisibility,
});
</script>

<style scoped></style>
