<template>
  <SendModal v-if="showModal" :persistent="true" size="lg" position="bottom">
    <template #header>
      <SendTypography as="h2" class="w-full" size="sm" variant="heading">
        {{ t("log_in_to_sendchallenges") }}
      </SendTypography>
    </template>
    <template #body>
      <div class="mb-4 flex flex-col gap-4">
        <SendInput
          v-model="v.username.$model"
          :label="t('account.username')"
          :placeholder="t('username')"
          :validation-status="v.username.$error ? 'error' : 'none'"
          sub-type="text"
          type="input"
        />

        <SendInput
          v-model="v.password.$model"
          :label="t('password')"
          :placeholder="t('password')"
          :validation-status="v.password.$error ? 'error' : 'none'"
          sub-type="password"
          type="input"
        />

        <SendInput
          v-model="v.repeatPassword.$model"
          :label="t('repeat_password')"
          :placeholder="t('repeat_password')"
          :validation-status="v.repeatPassword.$error ? 'error' : 'none'"
          sub-type="password"
          type="input"
        />

        <SendToggle
          v-model="v.eighteenPlusContent.$model"
          :label="t('account.age_check')"
          size="sm"
        />

        <div class="mt-8 flex items-center">
          <SendCheckbox v-model="v.acceptTerms.$model" size="md" />
          <SendTypography as="span" size="sm" variant="text">
            {{ t("create_account_i_accept") }}
            <a class="underline" href="/terms" target="_blank"
              >terms and conditions</a
            >
            {{ t("create_account_off") }}
          </SendTypography>
        </div>
      </div>
    </template>
    <template #footer>
      <SendBaseButton
        :disabled="v.$invalid || !v.$dirty"
        :label="t('log_in')"
        size="sm"
        variant="primary"
        @click="onSubmit"
      />
    </template>
  </SendModal>
</template>

<script lang="ts" setup>
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import type { Ref } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";
import SendBaseButton from "~/components/partials/SendBaseButton.vue";
import SendCheckbox from "~/components/partials/SendCheckbox.vue";
import SendInput from "~/components/partials/SendInput.vue";
import SendModal from "~/components/partials/SendModal.vue";
import SendToggle from "~/components/partials/SendToggle.vue";
import SendTypography from "~/components/partials/SendTypography.vue";

const { t } = useI18n();

defineProps<{
  showModal: boolean | Ref<boolean>;
}>();

const { verifyUserMutation } = useAuthStore();
const { verifyingUser } = toRefs(useAuthStore());

const emit = defineEmits<{
  (event: "update:showModal", value: boolean): void;
}>();

const toast = useToast();

const matchesPassword = (passwordRef) => {
  return helpers.withMessage(
    "Passwords do not match",
    (repeatPassword) => repeatPassword === passwordRef.value
  );
};

const form = reactive({
  eighteenPlusContent: false,
  username: "",
  password: "",
  repeatPassword: "",
  acceptTerms: false,
});

const v = useVuelidate(
  {
    username: { required },
    eighteenPlusContent: {
      required,
      accepted: (value) => value === true,
    },
    password: {
      required,
    },
    repeatPassword: {
      required,
      matchesPassword: matchesPassword(toRefs(form).password),
    },
    acceptTerms: {
      required,
      accepted: (value) => value === true,
    },
  },
  form
);

const onSubmit = () => {
  if (v.value.$invalid) return;

  emit("update:showModal", false);
  const verifyValues = {
    ...(verifyingUser.value.type === "code" && {
      code: verifyingUser.value.code,
    }),
    ...(verifyingUser.value.type === "link" && {
      hash: verifyingUser.value.hash,
      expires: +verifyingUser.value.expires,
    }),
  };

  verifyUserMutation.mutate({
    input: {
      email: verifyingUser.value.email,
      ...verifyValues,
      username: form.username,
      password: form.password,
      repeatPassword: form.repeatPassword,
      eighteenPlusContent: form.eighteenPlusContent,
    },
  });
};

verifyUserMutation.onDone((result) => {
  if (result.data.verifyUser.status.success == false) {
    toast.error(t(result.data.verifyUser.status.identifier));
    return;
  }

  navigateTo("/challenge/create");
});
</script>
