<template>
  <div v-bind="$attrs" class="flex items-center gap-5">
    <NuxtLink
      v-if="authStore.loggedIn && !showMenu"
      :to="`/profile/${username}/ongoing-challenges`"
    >
      <FwbAvatar
        rounded
        status-position="bottom-right"
        status="online"
        class="rounded-full border border-black"
        :img="avatar ?? undefined"
        :initials="displayName?.[0] ?? 'A'"
      />
    </NuxtLink>
    <button class="flex items-center text-white" @click="toggleMenu">
      <img
        class="h-auto max-w-full"
        src="/assets/images/hamburger.svg"
        alt="hamburger icon"
      />
      <span class="ml-2">
        {{ $t("menu") }}
      </span>
    </button>
  </div>
  <MenuModal v-model:show-menu="showMenu" />
</template>

<script setup lang="ts">
import FwbAvatar from "~/components/Flowbite/FwbAvatar/FwbAvatar.vue";
import MenuModal from "~/components/navigation/MenuModal.vue";

const nuxt = useNuxtApp();
const showMenu = ref(false);

const { userStore } = useUser();
const { avatar, displayName, username } = toRefs(userStore);

const authStore = useAuthStore();

nuxt.hooks.hook("page:start", () => {
  showMenu.value = false;
});

const toggleMenu = () => {
  showMenu.value = !showMenu.value;
};
</script>
