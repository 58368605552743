<template>
  <div class="relative h-screen bg-send-grey-bg lg:flex">
    <div
      class="flex min-h-20 items-center justify-between rounded-b-2xl bg-[#202020] p-2 px-4 shadow lg:w-80 lg:min-w-80 lg:flex-col lg:items-stretch lg:justify-normal lg:gap-16 lg:!rounded-r-2xl lg:rounded-b-none lg:px-10 lg:py-12"
    >
      <NuxtLink to="/challenge/create">
        <Logo />
      </NuxtLink>
      <SidebarMenu class="hidden lg:block" />
      <MobileMenu class="lg:hidden" />
    </div>
    <div
      class="hide-scrollbar relative flex h-[calc(100vh-74px)] w-full flex-col overflow-y-auto bg-send-grey-bg lg:h-screen"
    >
      <slot />
    </div>

    <div class="fixed bottom-6 right-8">
      <NuxtLink
        class="group flex h-16 w-16 transform items-center justify-center rounded-full bg-action-gradient transition-transform duration-200 ease-in-out hover:scale-110"
        to="/challenge/create"
      >
        <img
          alt="lightning icon"
          class="h-8"
          src="/assets/images/lightning-bolt-with-shadow.svg"
        />
      </NuxtLink>
    </div>

    <CompleteAccountModal
      v-if="showCompleteAccountModal"
      :show-modal="showCompleteAccountModal"
      @close="closeModal"
    />
    <LoginMagicLinkModal
      v-if="modalType === 'login-magic-link'"
      :show-modal="showModal"
      @close="closeModal"
    />

    <LoginUsernamePasswordModal
      v-if="modalType === 'login-username-password'"
      :show-modal="showModal"
      @close="closeModal"
    />

    <SendFullPageLoader v-if="fullscreenLoaderStore.showFullscreenLoader" />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, toRefs, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import CompleteAccountModal from "~/components/auth/CompleteAccountModal.vue";
import LoginMagicLinkModal from "~/components/auth/modals/LoginMagicLinkModal.vue";
import LoginUsernamePasswordModal from "~/components/auth/modals/LoginUsernamePasswordModal.vue";
import MobileMenu from "~/components/navigation/MobileMenu.vue";
import SidebarMenu from "~/components/navigation/SidebarMenu.vue";
import Logo from "~/components/partials/logo.vue";
import SendFullPageLoader from "~/components/partials/SendFullPageLoader.vue";
import { useAuthStore } from "~/stores/auth";
import { useFullscreenLoaderStore } from "~/stores/fullscreenLoaderStore";

const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();

const fullscreenLoaderStore = useFullscreenLoaderStore();
const { verifyingUser } = toRefs(authStore);

const showCompleteAccountModal = computed(() => {
  if (route.path.includes("/terms")) {
    return false;
  }

  return !!verifyingUser?.value?.type;
});

const showModal = ref(false);
const modalType = ref("");

const openModal = (type: string) => {
  modalType.value = type;
  showModal.value = true;
};

const closeModal = () => {
  showModal.value = false;
  modalType.value = "";
  router.push({ query: { ...route.query, modal: undefined } });
};

const checkModalFromUrl = () => {
  const modal = route.query.modal as string;

  if (modal && modal !== "") {
    openModal(modal);
  }
};

onMounted(async () => {
  checkModalFromUrl();
});

watch(route, () => {
  checkModalFromUrl();
});

if (verifyingUser?.value) {
  watch(verifyingUser, (newVal) => {
    if (!newVal) {
      return;
    }

    if (newVal.type) {
      return;
    }

    if (newVal.email) {
      openModal("complete-account");
    }
  });
}
</script>
