import validate from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@18.19.64_eslint@8.57.1_sass@1.81.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/builds/Partout/sendchallenges/src/frontend/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@18.19.64_eslint@8.57.1_sass@1.81.0_typescript@5.6.3_vite@5.4.11/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "logged-in": () => import("/builds/Partout/sendchallenges/src/frontend/middleware/logged-in.ts"),
  "not-logged-in": () => import("/builds/Partout/sendchallenges/src/frontend/middleware/not-logged-in.ts"),
  "user-is-admin": () => import("/builds/Partout/sendchallenges/src/frontend/middleware/user-is-admin.ts"),
  "user-is-owner": () => import("/builds/Partout/sendchallenges/src/frontend/middleware/user-is-owner.ts")
}