<template>
  <label :class="labelClasses" class="inline-flex items-center">
    <input
      v-model="model"
      :disabled="disabled"
      class="peer sr-only"
      type="checkbox"
    />
    <span
      :class="[checkboxSize, checkboxColor]"
      class="flex items-center justify-center rounded border-2 transition-all"
    >
      <svg
        :class="model ? 'opacity-100' : 'opacity-0'"
        class="h-full w-full"
        fill="none"
        stroke="white"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 13l4 4L19 7"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
        />
      </svg>
    </span>
    <span :class="checkboxLabelClasses" class="ml-3 text-sm font-medium">
      {{ label }}
    </span>
  </label>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import type { Maybe } from "~/gql/graphql";

type Sizes = "lg" | "md" | "sm";
type Color =
  | "send-primary-yellow"
  | "send-almost-black"
  | "send-label-1-dark-blue"
  | "send-label-2-purple"
  | "send-label-3-blue"
  | "send-online-green"
  | "action-gradient";

const checkboxSizeClasses: Record<Sizes, string> = {
  sm: "w-4 h-4",
  md: "w-5 h-5",
  lg: "w-6 h-6",
};

interface Props {
  color?: Color;
  size?: Sizes;
  disabled?: boolean;
  label?: string;
  modelValue?: boolean | Maybe<boolean>;
  required?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  color: "send-almost-black",
  size: "md",
  disabled: false,
  label: undefined,
  modelValue: false,
  required: false,
});

const emit = defineEmits(["update:modelValue"]);
const model = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit("update:modelValue", val);
  },
});

const labelClasses = computed(() =>
  props.disabled ? "cursor-not-allowed" : "cursor-pointer"
);

const checkboxSize = computed(() => checkboxSizeClasses[props.size]);

const checkboxColor = computed(() => {
  if (props.disabled) {
    return "peer-checked:bg-send-grey-80 peer-checked:border-send-grey-80 border-send-grey-80";
  }

  let checkedColor = "";

  switch (props.color) {
    case "send-primary-yellow":
      checkedColor =
        "peer-checked:bg-send-primary-yellow peer-checked:border-send-primary-yellow border-send-primary-yellow";
      break;
    case "send-almost-black":
      checkedColor =
        "peer-checked:bg-send-almost-black peer-checked:border-send-almost-black border-send-almost-black";
      break;
    case "send-label-1-dark-blue":
      checkedColor =
        "peer-checked:bg-send-label-1-dark-blue peer-checked:border-send-label-1-dark-blue border-send-label-1-dark-blue";
      break;
    case "send-label-2-purple":
      checkedColor =
        "peer-checked:bg-send-label-2-purple peer-checked:border-send-label-2-purple border-send-label-2-purple";
      break;
    case "send-label-3-blue":
      checkedColor =
        "peer-checked:bg-send-label-3-blue peer-checked:border-send-label-3-blue border-send-label-3-blue";
      break;
    case "send-online-green":
      checkedColor =
        "peer-checked:bg-send-online-green peer-checked:border-send-online-green border-send-online-green";
      break;
    case "action-gradient":
      checkedColor =
        "peer-checked:bg-action-gradient peer-checked:border-send-label-2-purple border-send-label-2-purple";
      break;
    default:
      checkedColor =
        "peer-checked:bg-send-almost-black peer-checked:border-send-almost-black border-send-almost-black";
      break;
  }

  return checkedColor;
});

const checkboxLabelClasses = computed(() => {
  let classes = "text-send-grey";

  if (props.required) {
    classes += " required";
  }

  return classes;
});
</script>

<style scoped>
span {
  transition:
    background-color 0.2s,
    border-color 0.2s;
}
</style>
