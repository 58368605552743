<template>
  <label :class="labelClasses" class="relative flex w-fit items-start gap-3">
    <div class="flex-shrink-0">
      <!-- This wrapper prevents the toggle from being affected -->
      <input
        v-model="model"
        :disabled="disabled"
        class="peer sr-only"
        type="checkbox"
      />
      <span
        :class="[toggleSize, toggleBallSize, toggleColor, toggleBallColor]"
        :style="toggleBallPosition"
        class="peer inline-block rounded-full border after:absolute after:rounded-full after:transition-all after:content-[''] peer-checked:after:translate-x-full peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-send-grey"
      />
    </div>
    <span
      class="break-words text-sm font-medium text-send-grey peer-checked:text-send-almost-black"
    >
      {{ label }}
    </span>
  </label>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import type { Maybe } from "~/gql/graphql";

type Sizes = "lg" | "md" | "sm";
type Color =
  | "send-primary-yellow"
  | "send-almost-black"
  | "send-grey"
  | "send-label-1-dark-blue"
  | "send-label-2-purple"
  | "send-label-3-blue"
  | "send-online-green"
  | "action-gradient";

const toggleSizeClasses: Record<Sizes, string> = {
  sm: "w-10 h-6",
  md: "w-12 h-7",
  lg: "w-16 h-8",
};

const toggleBallSizeClasses: Record<Sizes, string> = {
  sm: "after:top-[2px] after:left-[-2px] after:h-5 after:w-5",
  md: "after:top-[2px] after:left-[-2px] after:h-6 after:w-6",
  lg: "after:top-[2px] after:left-[2px] after:h-7 after:w-7",
};

interface Props {
  color?: Color;
  size?: Sizes;
  disabled?: boolean;
  label?: string;
  modelValue?: boolean | Maybe<boolean>;
}

const props = withDefaults(defineProps<Props>(), {
  color: "send-almost-black",
  size: "md",
  disabled: false,
  label: undefined,
  modelValue: false,
});

const emit = defineEmits(["update:modelValue"]);
const model = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    emit("update:modelValue", val);
  },
});

const labelClasses = computed(() =>
  props.disabled ? "cursor-not-allowed" : "cursor-pointer"
);

const toggleSize = computed(() => toggleSizeClasses[props.size]);

const toggleBallSize = computed(() => toggleBallSizeClasses[props.size]);

const toggleBallPosition = computed(() => {
  switch (props.size) {
    case "md":
    case "sm":
      return model.value
        ? { "--toggle-ball-left": "-2px" } // Active state
        : { "--toggle-ball-left": "2px" }; // Inactive state
    case "lg":
      return model.value
        ? { "--toggle-ball-left": "6px" } // Active state
        : { "--toggle-ball-left": "2px" }; // Inactive state
    default:
      return {};
  }
});

const toggleColor = computed(() => {
  if (props.disabled) {
    return "peer-checked:bg-send-grey-80 border-send-grey-80";
  }

  let color = "";

  switch (props.color) {
    case "send-primary-yellow":
      color = "peer-checked:bg-send-primary-yellow border-send-primary-yellow";
      break;
    case "send-grey":
      color = "peer-checked:bg-send-almost-black border-send-grey";
      break;
    case "send-label-1-dark-blue":
      color =
        "peer-checked:bg-send-label-1-dark-blue border-send-label-1-dark-blue";
      break;
    case "send-label-2-purple":
      color = "peer-checked:bg-send-label-2-purple border-send-label-2-purple";
      break;
    case "send-label-3-blue":
      color = "peer-checked:bg-send-label-3-blue border-send-label-3-blue";
      break;
    case "send-online-green":
      color = "peer-checked:bg-send-online-green border-send-online-green";
      break;
    case "action-gradient":
      color = "peer-checked:bg-action-gradient border-action-gradient";
      break;
    default:
      color = "peer-checked:bg-send-almost-black border-send-almost-black";
      break;
  }

  return color;
});

const toggleBallColor = computed(() => {
  if (props.disabled) {
    return model.value ? "after:bg-white" : "after:bg-send-grey-80";
  }

  switch (props.color) {
    case "send-primary-yellow":
      return model.value ? "after:bg-white" : "after:bg-send-primary-yellow";
    case "send-grey":
      return model.value ? "after:bg-white" : "after:bg-send-grey";
    case "send-label-1-dark-blue":
      return model.value ? "after:bg-white" : "after:bg-send-label-1-dark-blue";
    case "send-label-2-purple":
      return model.value ? "after:bg-white" : "after:bg-send-label-2-purple";
    case "send-label-3-blue":
      return model.value ? "after:bg-white" : "after:bg-send-label-3-blue";
    case "send-online-green":
      return model.value ? "after:bg-white" : "after:bg-send-online-green";
    case "action-gradient":
      return model.value ? "after:bg-white" : "after:bg-action-gradient";
    default:
      return model.value ? "after:bg-white" : "after:bg-send-almost-black";
  }
});
</script>

<style scoped>
span::after {
  left: var(--toggle-ball-left, 0px);
  transition:
    background-color 0.2s,
    transform 0.2s;
}
</style>
