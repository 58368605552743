<template>
  <SendModal v-if="showModal" position="bottom" size="lg" @close="closeModal">
    <template #header>
      <SendTypography
        as="h2"
        class="w-full py-8 md:text-center"
        size="md"
        variant="heading"
      >
        {{ $t("log_in_with_username_password") }}
      </SendTypography>
    </template>
    <template #body>
      <div class="flex flex-col gap-4">
        <LoginAccountForm
          :on-login-success="onLoginSuccess"
          @close="closeModal"
          @login="onLogin"
        />
      </div>
      <div class="relative inline-flex w-full items-center justify-center">
        <hr class="my-4 h-[1px] w-11/12 border-0 bg-gray-300 lg:my-8" />
        <div class="text-grey absolute left-1/2 -translate-x-1/2 bg-white px-4">
          {{ $t("or") }}
        </div>
      </div>
      <div class="flex items-center justify-center pb-4">
        <NuxtLink
          class="cursor-pointer bg-action-gradient bg-clip-text font-medium [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]"
          @click="goToLoginMagicLink"
        >
          {{ $t("log_in_with_magic_link") }}
        </NuxtLink>
      </div>
    </template>
  </SendModal>
</template>

<script lang="ts" setup>
import LoginAccountForm from "~/components/auth/LoginAccountForm.vue";
import SendModal from "~/components/partials/SendModal.vue";
import SendTypography from "~/components/partials/SendTypography.vue";
import { useProfileStore } from "~/stores/profilePage";

const router = useRouter();
const route = useRoute();
const { username } = toRefs(useProfileStore());

const props = defineProps<{
  showModal: boolean;
  onLoginSuccess?: () => void;
  handleGoToMagicLink?: () => void;
}>();

const emit = defineEmits(["close"]);

const onLogin = async () => {
  if (props.onLoginSuccess) {
    props.onLoginSuccess();
  } else if (username?.value) {
    await router.push(`/profile/${username.value}/ongoing-challenges`);
    return;
  } else {
    await router.push("/settings");
  }

  closeModal();
};

const goToLoginMagicLink = () => {
  if (props.handleGoToMagicLink) {
    props.handleGoToMagicLink();
    return;
  }

  router.push({ query: { ...route.query, modal: "login-magic-link" } });
};

const closeModal = () => {
  emit("close");
};
</script>

<style scoped></style>
